import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Scrollspy from 'react-scrollspy';
import { Link } from 'gatsby';
import { DrawerContext } from '../../contexts/DrawerContext';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import ScrollSpyContainer from './scrollspy.style.js';
import { openModal, closeModal, Modal } from '@redq/reuse-modal';

const ScrollSpyMenu = ({
  className,
  menuItems,
  resourceItems,
  productItems,
  companyItems,
  drawerClose,
  ...props
}) => {
  const { dispatch } = useContext(DrawerContext);
  // empty array for scrollspy items
  const scrollItems = [];

  // convert menu path to scrollspy items
  menuItems.forEach((item) => {
    scrollItems.push(item.path.slice(1));
  });

  // Add all classs to an array
  const addAllClasses = ['scrollspy__menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  // Close drawer when click on menu item
  const toggleDrawer = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const [productOpen, setProductOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [comapanyOpen, setCompanyOpen] = useState(false);
  const [isDataEnrichmentHovered, setIsDataEnrichmentHovered] = useState(false);

  const button = {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'tertiaryWithBg',
    minHeight: 'auto',
    height: '40px',
  };
  const IntroVideo = () => (
    <iframe
      title="ReactivePro"
      width="860"
      height="515"
      src="https://www.youtube.com/embed/YWOOAv_e0cI"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
  const CloseModalButton = () => (
    <Button
      className="btn modalCloseBtn"
      variant="fab"
      onClick={() => closeModal()}
      icon={<i className="flaticon-plus-symbol" />}
    />
  );
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0,
        },
        enableResizing: {
          bottom: true,
          bottomLeft: true,
          bottomRight: true,
          left: true,
          right: true,
          top: true,
          topLeft: true,
          topRight: true,
        },
      },
      component: IntroVideo,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  return (
    <Scrollspy
      items={scrollItems}
      className={addAllClasses.join(' ')}
      drawerClose={drawerClose}
      {...props}
    >
      {menuItems.map((menu, index) => (
        <>
          {menu.label === 'Product' && (
            <li
              className="cursor-pointer"
              key={`menu-item-${index}`}
              onClick={() => setProductOpen((prev) => !prev)}
              onMouseEnter={() => setProductOpen(true)}
              onMouseLeave={() => setProductOpen(false)}
            >
              {productOpen ? (
                <ScrollSpyContainer>
                  <a href={menu.path}>
                    {menu.label}
                    <svg
                      style={{
                        height: '5px',
                        marginLeft: '4px',
                        position: 'relative',
                        top: '-2px',
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 8"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                      />
                    </svg>
                  </a>
                  <div className="dropdown-container navDropdown">
                    <div
                      className="nav-dropdown-left-wrapper"
                      onMouseEnter={() => setIsDataEnrichmentHovered(true)}
                      onMouseLeave={() => setIsDataEnrichmentHovered(false)}
                    >
                      {productItems.map((menu, index) => (
                        <a
                          key={`product-item-${index}`}
                          href={menu.path}
                          onMouseEnter={() =>
                            setIsDataEnrichmentHovered(
                              menu.label === 'Data Enrichment'
                            )
                          }
                        >
                          {menu.label}
                          {menu.label === 'Data Enrichment' && (
                            // Include the specific SVG for "Data Enrichment" only
                            <svg
                              style={{
                                height: '5px',
                                marginLeft: '4px',
                                position: 'relative',
                                top: '-2px',
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 8"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                              />
                            </svg>
                          )}
                        </a>
                      ))}
                    </div>
                    {isDataEnrichmentHovered && (
                      <div
                        className="nav-dropdown-right-wrapper visible"
                        onMouseEnter={() => setIsDataEnrichmentHovered(true)}
                        onMouseLeave={() => setIsDataEnrichmentHovered(false)}
                      >
                        {/* Show additional content for "Data Enrichment" */}
                        <Link to="/website-form-enrichment-apis/">
                          Website Form Enrichment APIs
                        </Link>
                        <Link to="/crm-cleanup-apis/">CRM Cleanup APIs</Link>
                      </div>
                    )}
                  </div>

                  {/* <div className='extra-container'>
                      {productItems.map((menu, index) => (
                        <a className="highlight" key={`product-item-${index}`} href={menu.path}>{menu.label}</a>
                      ))}
                    </div> */}
                </ScrollSpyContainer>
              ) : (
                <a>
                  {menu.label}
                  <svg
                    style={{
                      height: '5px',
                      marginLeft: '4px',
                      position: 'relative',
                      top: '-2px',
                    }}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 8"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                    />
                  </svg>
                </a>
              )}
            </li>
          )}
          {menu.label === 'Resources' && (
            <li
              className="cursor-pointer"
              key={`menu-item-${index}`}
              onClick={() => setResourcesOpen((prev) => !prev)}
              onMouseEnter={() => setResourcesOpen(true)}
              onMouseLeave={() => setResourcesOpen(false)}
            >
              {resourcesOpen ? (
                <ScrollSpyContainer>
                  <a>
                    {menu.label}
                    <svg
                      style={{
                        height: '5px',
                        marginLeft: '4px',
                        position: 'relative',
                        top: '-2px',
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 8"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                      />
                    </svg>
                  </a>
                  <div className="dropdown-container navDropdown">
                    <div className="nav-dropdown-left-wrapper">
                      {resourceItems.map((menu, index) => (
                        <a key={`resource-item-${index}`} href={menu.path}>
                          {menu.label}
                        </a>
                      ))}
                    </div>
                  </div>
                  {/* <div className='extra-container'>
                      {resourceItems.map((menu, index) => (
                        <a className="highlight" key={`resource-item-${index}`} href={menu.path}>{menu.label}</a>
                      ))}
                    </div> */}
                </ScrollSpyContainer>
              ) : (
                <a>
                  {menu.label}
                  <svg
                    style={{
                      height: '5px',
                      marginLeft: '4px',
                      position: 'relative',
                      top: '-2px',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 8"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                    />
                  </svg>
                </a>
              )}
            </li>
          )}
          {menu.label === 'Company' && (
            <li
              className="cursor-pointer"
              key={`menu-item-${index}`}
              onClick={() => setCompanyOpen((prev) => !prev)}
              onMouseEnter={() => setCompanyOpen(true)}
              onMouseLeave={() => setCompanyOpen(false)}
            >
              {comapanyOpen ? (
                <ScrollSpyContainer>
                  <a>
                    {menu.label}
                    <svg
                      style={{
                        height: '5px',
                        marginLeft: '4px',
                        position: 'relative',
                        top: '-2px',
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 8"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                      />
                    </svg>
                  </a>
                  <div className="dropdown-container navDropdown">
                    <div className="nav-dropdown-left-wrapper">
                      {companyItems.map((menu, index) => (
                        <a key={`company-item-${index}`} href={menu.path}>
                          {menu.label}
                        </a>
                      ))}
                    </div>
                  </div>
                  {/* <div className='extra-container'>
                      {companyItems.map((menu, index) => (
                        <a className="highlight" key={`company-item-${index}`} href={menu.path}>{menu.label}</a>
                      ))}
                    </div> */}
                </ScrollSpyContainer>
              ) : (
                <a>
                  {menu.label}
                  <svg
                    style={{
                      height: '5px',
                      marginLeft: '4px',
                      position: 'relative',
                      top: '-2px',
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 8"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
                    />
                  </svg>
                </a>
              )}
            </li>
          )}
          {menu.label !== 'Resources' &&
            menu.label !== 'Company' &&
            menu.label !== 'Product' && (
              <li key={`menu-item-${index}`}>
                {menu.staticLink ? (
                  <a href={menu.path}>{menu.label}</a>
                ) : (
                  <>
                    {drawerClose ? (
                      <a
                        href={menu.path}
                        offset={menu.offset}
                        onClick={toggleDrawer}
                      >
                        {menu.label}
                      </a>
                    ) : (
                      <a href={menu.path} offset={menu.offset}>
                        {menu.label}
                      </a>
                    )}
                  </>
                )}
              </li>
            )}
        </>
      ))}
    </Scrollspy>
  );
};

ScrollSpyMenu.propTypes = {
  /** className of the ScrollSpyMenu. */
  className: PropTypes.string,

  /** menuItems is an array of object prop which contain your menu
   * data.
   */
  menuItems: PropTypes.array.isRequired,

  /** Class name that apply to the navigation element paired with the content element in viewport. */
  currentClassName: PropTypes.string,

  /** Class name that apply to the navigation elements that have been scrolled past [optional]. */
  scrolledPastClassName: PropTypes.string,

  /** HTML tag for Scrollspy component if you want to use other than <ul/> [optional]. */
  componentTag: PropTypes.string,

  /** Style attribute to be passed to the generated <ul/> element [optional]. */
  style: PropTypes.object,

  /** Offset value that adjusts to determine the elements are in the viewport [optional]. */
  offset: PropTypes.number,

  /** Name of the element of scrollable container that can be used with querySelector [optional]. */
  rootEl: PropTypes.string,

  /**
   * Function to be executed when the active item has been updated [optional].
   */
  onUpdate: PropTypes.func,
};

ScrollSpyMenu.defaultProps = {
  componentTag: 'ul',
  currentClassName: 'is-current',
};

export default ScrollSpyMenu;
