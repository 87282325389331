import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body{
    font-family: 'Poppins', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Poppins', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper{
    @media (max-width: 767px) {
      width: 300px!important;
    }
    .drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }
      .mobile_menu {
        margin-bottom: 40px;
        flex-grow: 1;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
        li{
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }
          a{
            font-size: 20px;
            font-weight: 400;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }
            &:hover {
              color: #2aa275;
            }
            &:before{
              content: '';
              width: 7px;
              height: 7px;
              background: #2aa275;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
      .navbar_drawer_button button{
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .reusecore-drawer__close{
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }
      &:before{
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: #2aa275;
        transform: rotate(45deg);
        display: block;
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: #000;
            &:hover {
              color: #2aa275;
            }
          }
          &.is-current {
            a {
              color: #2aa275;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #2aa275;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 15px 0;
    background-color: white;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 10px;
      li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        /* Weird hacks to make accessing menu better */
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: -20px;
        margin-top: -20px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #edcd37;
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: black;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #edcd37;
          }
          @media screen and (max-width: 1219px) and (min-width: 991px) {
            font-size: 14px;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      margin-left: 10px;
      color: white;
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }

  .thanksgiving {
    cursor: pointer;
    display: flex;
    justify-content: center;
    z-index: -1;
    position: fixed;
    top: 70px;
    left: 0;
    padding: 0 !important;

    color: white;
    height: 50px !important;

    h2 {
      text-align: center;
      font-size: 22px;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .widthset-t {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 0;
      svg {
        margin-left: auto;
        margin-right: 0;
      }

      .sticky-heading {
        text-align: center;
      }
    }
  }
`;
