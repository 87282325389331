import styled from 'styled-components';

const ScrollSpyContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    cursor: pointer;
  }

  .highlight {
    background-color: white;
    padding: 20px !important;
  }

  > .dropdown-container {
    position: absolute;
    top: 50px;
    display: flex;
    border-radius: 5px;
    box-shadow: 0 1px 10px 5px rgb(17 17 17 / 5%);
    background: white;

    .nav-dropdown-left-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 1rem;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      // grid-row-gap: 0.2rem;
    }
    .nav-dropdown-right-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 350px;
      padding: 1.5rem;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #f6f7fd;
    }
    .nav-dropdown-button-wrapper {
      display: flex;
    }
    .nav-dropdown-button-wrapper a {
      margin: 0px;
      padding: 0px !important;
    }
  }
  div.navDropdown {
    z-index: 9;
  }
  div.navDropdown > div.nav-dropdown-left-wrapper > a {
    padding: 10px;
    margin-bottom: 0px;
  }
  div.navDropdown > div.nav-dropdown-left-wrapper > a:hover {
    background-color: #f6f7fd !important;
    border-radius: 5px;
    color: #03baed;
  }
  div.dropdown-container.navDropdown {
    top: 70px;
  }

  > .extra-container {
    position: absolute;
    top: 55px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 gray;

    > h4 {
      margin: 0;
      background-color: white;
      padding: 20px;
    }
  }

  @media only screen and (max-width: 991px) {
    > .extra-container {
      position: relative;
      top: 0;
    }
    div.nav-dropdown-left-wrapper {
      max-width: 250px;
    }
    div.nav-dropdown-right-wrapper {
      display: none;
    }
  }
  .intro-video-thumbnail {
    width: 100%;
    height: 140px;
    margin-bottom: 20px;
    background: url(https://stgbootdiagclodura.blob.core.windows.net/website/logo-016f7f22b37dca0c6dd8bdbd5f8ae130.png);
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
  }
  .thumbnail-icon {
    color: #03baed;
    font-size: 42px;
  }
`;

export default ScrollSpyContainer;
