import React from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Container from 'common/components/UI/Container';
import '@reach/menu-button/styles.css';
import './navbar.css';
import bg from 'common/assets/image/saasModern/bg.png';
import Mail from 'common/assets/image/saasModern/Email.png';
import { Link } from 'gatsby';

const Thanksgiving = ({ navbarStyle }) => {
  return (
    <NavbarWrapper
      style={{
        cursor: 'default',
        width: '100%',
        color: 'Black',
        backgroundColor: '#f2fbfe',
        padding: '10px 0', // Add padding for spacing
      }}
      {...navbarStyle}
      className="thanksgiving"
    >
      <Container className="widthset-t">
        <h2 className="sticky-heading">
          <img src={Mail} style={{ width: '20px', height: '20px' }} /> AI
          Writer: Write emails smarter, sell more and close more deals. ➡️{' '}
          <Link
            target={'_blank'}
            href={'/app/#/auth/register'}
            style={{
              color: 'black',
              textDecoration: 'underline',
              // marginLeft: '4px',
            }}
          >
            Try Now!
          </Link>{' '}
          🚀
        </h2>
      </Container>
    </NavbarWrapper>
  );
};

Thanksgiving.propTypes = {
  navbarStyle: PropTypes.object,
};

Thanksgiving.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
};

export default Thanksgiving;
